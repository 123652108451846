import React from "react"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "./screens/respiratory-health-test/hero"
import ThanksComponent from "./screens/respiratory-health-test/thank-you"
import BodyLanding from "./screens/respiratory-health-test/body-landing"

const ThankYou = ({ location }) => {
	const firstName = (location.state) ? location.state.first_name : ""
	return (
		<main>
			<Header />
			<Hero />
			<ThanksComponent first_name={firstName} />
			<BodyLanding />
		</main>
	)
}

export default ThankYou

export const Head = () => (
	<Seo
	  title="Respiratory Health Test "
	  description="In less than 30 minutes, the instrument free Respiratory Health Test provides lab accurate results to confidently disposition patients."
	/>
  )
