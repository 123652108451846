import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "../../../utils/breakpoints/"
import { colors } from "../../../utils/variables/"

// Components
import Container from "../../../components/container"
import { ExternalButtonLink } from "../../../components/buttons"

const StyledContainer = styled(Container)`
  max-width: 1150px;
  overflow: hidden;
  padding: 0px;
`

const StyledBodyLanding = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: 40px 10px;
  column-gap: 30px;

  ${breakpoint.small`
    flex-wrap: nowrap;
    padding: 180px 20px 40px;
  `}

  ${breakpoint.medium`
    flex-wrap: nowrap;
    padding: 120px 20px 40px;
  `}

  .block {
    width: 100%;

    ${breakpoint.small`
      width: 100%;
    `}

    &.block--white {
      display: flex;
    }

    &.block--image {
		max-width: 250px;
		width: 100%;
		margin: 0 auto;
		padding-top: 50px;

		${breakpoint.medium`
		padding-top: 100px;
	  `}

		.side_image  {
			width: 250px;
			height: auto;
		}
			ul {
				display: inline-block;
			}

			.line {
				display: block;
				margin: 0 auto;
			}

			ul li:first-child .line {
			  display: none;
			}

			.line {
				 border-left: 3px solid ${colors.purpleDark};
				 height: 100px;
				 margin: 0 50%;
			}
	 }

	  .feature__image {
	  	max-width: 600px!important;
		position: relative;
	  	margin: 0px auto!important;
	  
  		${breakpoint.small`
			margin: -360px auto!important;
			width: 450px;
			position: absolute !important;
		`}

  		${breakpoint.large`
			margin: -390px auto!important;
			width: 550px;
			position: absolute !important;
		`}

	  }
  
  .lower {
  		${breakpoint.small`
			margin: -380px auto;
			width: 360px;
			position: absolute !important;
		`}

  		${breakpoint.medium`
			margin: -380px auto;
			width: 420px;
			position: absolute !important;
		`}
  }


    .block__content, .block__content_thankyou {
    	padding-top: 0;
   
     ${breakpoint.small`
	 	padding-top: 100px;		
	  `}
 
		h2 {
			font-size: 26px;
			margin-block: 1rem 2rem;
			text-align: center;

			${breakpoint.small`
				font-size: 36px;
				text-align: left;
				padding-top: 100px;		
				`}
		}

		h3 {
		  margin-top: 40px;
		  margin-bottom: 10px;
		  text-align: center;
  
		${breakpoint.small`
			text-align: left;
		`}
		}

		p {
			font-size: 19px;
			line-height: 1.6em;
			width: 90%;
			text-align: center;
			padding-inline: 20px;

		${breakpoint.small`
			width: 85%;
			padding-inline: 0;
			text-align: left;
		`}

		}

	  .note {
	  	margin-top: 20px;
		font-size: 12px;
		}
	  
    }
    
    .block__content_thankyou {
		 ${breakpoint.small`
			padding-top: 150px;
		  `}
		 ${breakpoint.medium`
			padding-top: 200px;
		  `}
    	}

    .buttonLink {
    	margin: 10px auto 0;

		 ${breakpoint.small`
			margin: 0;
		  `}
    }

    ${ExternalButtonLink} {
		width: auto;
		}
    
  }
`


const BodyLanding = props => {
	return (
		<StyledContainer>
			<StyledBodyLanding>
				<div className="block block--white">
					<div className="block__content">
						<StaticImage
							src="../../../assets/images/respiratory-health-test/device-angle.png"
							width={550}
							alt="Visby Medical Respiratory Health Test"
							className="feature__image"
							data-aos="fade-up"
						/>
						<p className="buttonLink" data-aos="fade-up">
							<ExternalButtonLink 
								href="https://www.visbymedical.com/respiratory-health-test/" 
								target="_blank" 
								rel="noopener noreferrer" 
								backgroundcolor="orange"
								aria-label="Learn More about Visby Medical Respiratory Health Test">
								Learn More
							</ExternalButtonLink>
						</p>
						<p className="note" data-aos="fade-up">
							This product has not been FDA cleared or approved, but has been authorized for emergency use by FDA under 
							an EUA for use by authorized laboratories. This product has been authorized only for the detection and 
							differentiation of nucleic acid from SARS-CoV-2, influenza A, and influenza B, not for any other viruses or pathogens; 
							and the emergency use of this product is only authorized for the duration of the declaration that circumstances 
							exist justifying the authorization of emergency use of in vitro diagnostics for detection and/or diagnosis of 
							COVID-19 under Section 564(b)(1) of the Federal Food, Drug, and Cosmetic Act, 21 U.S.C. § 360bbb-3(b)(1), 
							unless the declaration is terminated or authorization is revoked sooner.
						</p>

					</div>
				</div>

			</StyledBodyLanding>
		</StyledContainer>
	)
}

export default BodyLanding
