import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "../../../utils/breakpoints/"
import { colors } from "../../../utils/variables/"


// BG
import HeroBg from "../../../assets/images/respiratory-health-test/Visby-Respiratory-Health-Test-in-hand.png"

// Components
import Container from "../../../components/container"

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: center;
  max-width: 1300px;
  flex-direction: inherit;
  padding: 0;
  margin: 0 auto;

  ${breakpoint.small`
    flex-direction: row;
`}

  ${breakpoint.medium`
    flex-direction: row;
  	justify-content: space-between;
  `}
`

const StyledHero = styled.header`
  display: flex;
  flex-wrap: wrap;
  border-bottom-width: 0;
  overflow: hidden;
  background: url(${HeroBg});
  background-size: cover;
  background-position-x: center!important;
  background-position-y: center!important;
  background-repeat: no-repeat;
  flex-direction: column-reverse; 
  min-height: 400px;
  max-width: 1300px;
  margin: 0 auto;
    
  ${breakpoint.small`
    height: 700px;
  `}
  
  ${breakpoint.large`
	width: 1300px;
  `}
  
  ${breakpoint.extraLarge`
	margin: 0 auto;
  `}


  .block--text {
    width: 100%!important;
    max-width: 1412px;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    text-align: center;

	  ${breakpoint.medium`
      flex-wrap: nowrap;
      flex-direction: row;
      text-align: left;
	  `}
    
    .column {
      padding: 0px;
      height: 100%;
      width: 100%;
      padding: 0;

      ${breakpoint.small`
        padding: 30px 0;
        width: auto;
      `}
    }
    
	h1 { 
		font-size: 40px;
		color: ${colors.white}; 
		line-height: 1.1em;
		margin 0 auto 30px;

      ${breakpoint.small`
		font-size: 60px;
		margin: 2rem 0 2rem 5rem;
      `}
	  }

	p { 
		margin: 1rem;
		line-height: inherit;

		  ${breakpoint.small`
				width: 65%;
				line-height: 1.8em;
		  `}
		}
	}

`

const Hero = props => {
  return (
    <StyledHero data-aos="fade">
      <div className="block--text">
        <StyledContainer>
          <div className="column">
            <h1 data-aos="fade-up">The power of PCR<br />in your hands</h1>
          </div>
          <div className="column">
          </div>
        </StyledContainer>
      </div>
    </StyledHero>
  )
}

export default Hero
